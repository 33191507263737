import {
  Box,
  Drawer,
  Stack,
  Tooltip,
  styled,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  DialogContentText,
  AppBar,
  Toolbar,
  IconButton,
  Fab
} from '@mui/material';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { useLocation, useNavigate } from 'react-router';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import palette from '@utils/palette';
import { shouldForwardProp } from '@utils/mui';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import MakeReadyIcon from '@mui/icons-material/ListAltRounded';
import ApartmentIcon from '@mui/icons-material/ApartmentRounded';
import WorkOutlineRoundedIcon from '@mui/icons-material/WorkOutlineRounded';
import { ROOT_ROUTES } from '@utils/routes';
import { useAuth } from '@contexts/AuthContext';
import { useIsMobile } from '@hooks/useIsMobile';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';

export const IconContainer = styled(Box, {
  shouldForwardProp: shouldForwardProp(['active'])
})<{ active?: boolean }>(({ active }) => ({
  borderRadius: '.5rem',
  padding: '.5rem',
  width: '3rem',
  height: '3rem',
  cursor: 'pointer',
  backgroundColor: active ? '#ffffff33' : '',
  color: 'white',
  opacity: active ? '1' : '.65',
  '& svg': {
    height: '2rem',
    width: '2rem'
  }
}));

const StyledFab = styled(Fab)({
  position: 'absolute',
  zIndex: 1,
  top: -30,
  left: 0,
  right: 0,
  margin: '0 auto',
});

export default function Sidebar() {
  const { logout, isPmRole, isAdminRole, user } = useAuth();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleLogout = () => {
    logout();
    setOpen(false);
  };

  const isMREnabled = () => {
    return true;
  }

  return (
    <>
      {!isMobile && <Drawer
        ModalProps={{
          keepMounted: true
        }}
        anchor={'left'}
        variant={'permanent'}
        sx={{
          [`& .MuiDrawer-paper`]: {
            border: 'none',
            backgroundColor: palette.primary[10],
          },
          '&.MuiModal-root.MuiDrawer-root': {
            zIndex: 1301
          }
        }}
      >
        <Stack
          sx={{ pt: 5, pb: 6, px: 2, height: '100%' }}
          spacing={2}
        >
          <Stack alignItems="center">
            <Logo
              style={{ height: '3rem', width: '3rem', marginBottom: '5rem', cursor: 'pointer' }} onClick={() => navigate(ROOT_ROUTES.HOME)}
            />
            <Stack spacing={2}>
              <Tooltip title={<FormattedMessage id="Dashboard"/>} placement='right' sx={{ fontSize: '.875rem'}}>
                <IconContainer
                  active={location.pathname === ROOT_ROUTES.HOME}
                  onClick={() => navigate(ROOT_ROUTES.HOME)}
                >
                  <InsightsRoundedIcon />
                </IconContainer>
              </Tooltip>
              {isMREnabled()  && <Tooltip title={<FormattedMessage id="Make Ready"/>} placement='right' sx={{ fontSize: '.875rem'}}>
                <IconContainer
                  active={location.pathname === ROOT_ROUTES.MAKE_READY}
                  onClick={() => navigate(ROOT_ROUTES.MAKE_READY)}
                >
                  <MakeReadyIcon />
                </IconContainer>
              </Tooltip>}
              <Tooltip title={<FormattedMessage id='Work Orders' />} placement='right'>
                <IconContainer
                  active={location.pathname === ROOT_ROUTES.WORK_ORDERS}
                  onClick={() => navigate(ROOT_ROUTES.WORK_ORDERS)}
                >
                  <WorkOutlineRoundedIcon />
                </IconContainer>
              </Tooltip>
              {!isPmRole() && <Tooltip title={<FormattedMessage id='Work Orders' />} placement='right'>
                <IconContainer
                  active={location.pathname === ROOT_ROUTES.ALL_WORK_ORDERS}
                  onClick={() => navigate(ROOT_ROUTES.ALL_WORK_ORDERS)}
                >
                  <WorkOutlineRoundedIcon />
                </IconContainer>
              </Tooltip>}
              {isPmRole() && (
                <Tooltip title={<FormattedMessage id="Manage Users" />} placement='right'>
                  <IconContainer
                    active={location.pathname === ROOT_ROUTES.MANAGE_USERS}
                    onClick={() => navigate(ROOT_ROUTES.MANAGE_USERS)}
                  >
                    <PeopleOutlineRoundedIcon />
                  </IconContainer>
                </Tooltip>
              )}
              {isAdminRole() && (
                <Tooltip title={<FormattedMessage id="Manage Properties" />} placement='right'>
                  <IconContainer
                    active={location.pathname === ROOT_ROUTES.MANAGE_PROPERTIES}
                    onClick={() => navigate(ROOT_ROUTES.MANAGE_PROPERTIES)}
                  >
                    <ApartmentIcon />
                  </IconContainer>
                </Tooltip>
              )}
              {!isPmRole() && (
                <Tooltip title={<FormattedMessage id="My Profile" />} placement='right'>
                  <IconContainer
                    active={location.pathname === ROOT_ROUTES.PROFILE}
                    onClick={() => navigate(ROOT_ROUTES.PROFILE)}
                  >
                    <PeopleOutlineRoundedIcon />
                  </IconContainer>
                </Tooltip>
              )}

              <Tooltip title={<FormattedMessage id="Settings"/>} placement='right'>
                <IconContainer
                  onClick={() => navigate(ROOT_ROUTES.SETTINGS)}
                  active={location.pathname === ROOT_ROUTES.SETTINGS}
                >
                  <SettingsOutlinedIcon/>
                </IconContainer>
              </Tooltip>
            </Stack>
          </Stack>

          <Stack spacing={4}>
            <Tooltip title="Log Out" placement='right'>
              <IconContainer onClick={() => setOpen(true)}>
                <ExitToAppRoundedIcon/>
              </IconContainer>
            </Tooltip>
          </Stack>
        </Stack>
      </Drawer>}
      {isMobile && <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
        <Toolbar sx={{display: 'flex', justifyContent: 'space-between', px: 1}}>
          <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.HOME)}>
            <InsightsRoundedIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Dashboard</Typography>
          </IconButton>
          {isMREnabled() &&<IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.MAKE_READY)}>
            <MakeReadyIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Make Ready</Typography>
          </IconButton>}
          <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.WORK_ORDERS)}>
            <WorkOutlineRoundedIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Work Orders</Typography>
          </IconButton>
          {!isPmRole() && <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.ALL_WORK_ORDERS)}>
            <WorkOutlineRoundedIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>All Work Orders</Typography>
          </IconButton>}
          {isPmRole() && <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.MANAGE_USERS)}>
            <PeopleOutlineRoundedIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Users</Typography>
          </IconButton>}
          {isAdminRole() && <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.MANAGE_PROPERTIES)}>
            <ApartmentIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Properties</Typography>
          </IconButton>}
          <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => navigate(ROOT_ROUTES.SETTINGS)}>
            <SettingsOutlinedIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Settings</Typography>
          </IconButton>
          <IconButton sx={{color: '#fff', display: 'flex', flexDirection: 'column'}} onClick={() => setOpen(true)}>
            <ExitToAppRoundedIcon />
            <Typography sx={{fontSize: '8px', color: '#fff', fontWeight: 500}}>Logout</Typography>
          </IconButton>
        </Toolbar>
      </AppBar>}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md">
        <DialogTitle sx={{ p: 4 }}>
          <Typography variant="h4" fontWeight={500}>
            Log Out
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ px: 4 }}>
          <DialogContentText>
            <Typography variant="body1" fontWeight={400}>
              Are you sure you want to log out?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ px: 4, py: 3 }}>
          <Button
            variant={'outlined'}
            sx={{ borderRadius: '8px', height: '52px' }}
            onClick={() => setOpen(false)}
            size="large"
          >
            Return
          </Button>
          <Button
            variant={'contained'}
            sx={{ borderRadius: '8px', height: '52px', color: '#FFF' }}
            onClick={handleLogout}
            size="large"
          >
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
