import { Box, Grid, MenuItem as MuiMenuItem, Typography, MenuItem, ListItemText, Stack } from "@mui/material";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import { FormInputField, FormSelectField } from "@ui/form";
import PrimaryButton from "@components/buttons/PrimaryButton";
import { SelectChip } from "@ui/select";
import palette from "@utils/palette";
import BasicDatePicker from "@ui/dateInput";
import { DateTime } from 'luxon';
import BasicTimePicker from "@ui/time-picker";
import { useAuth } from "@contexts/AuthContext";
import { useEffect, useState } from "react";

interface ITechniciansAndStatus {
    control: Control<any>;
    errors: FieldErrors<any>;
    onNext: () => void;
    setValue: any;
    isValid: boolean;
    watch: any;
}

export default function TechniciansAndStatus({ control, errors, onNext, setValue, isValid, watch }: ITechniciansAndStatus) {

    const [apptType, setApptType] = useState('');

    useEffect(() => {
        const subscription = watch((value: any, { name, type }: any) =>
            setApptType(value.appointmentType)
        )
        return () => subscription.unsubscribe()
    }, [watch])

    const {user} = useAuth();
    return <>
        <Stack sx={{ mt: 3, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Technicians & Status</Typography>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                        <Controller
                            name="technicians"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <FormSelectField
                                        {...field}
                                        label="Select technician(s) (Optional)"
                                        
                                    >
                                        {(user?.techUsers || []).map((tech) => (
                                            <MenuItem key={tech.name} value={tech.name}>
                                                <ListItemText primary={tech.name} />
                                            </MenuItem>
                                        ))}
                                    </FormSelectField>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="workOrderStatus"
                        control={control}
                        rules={{
                            required: "Work Order Status is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Select the status of Work Order"
                                    {...field}
                                    error={errors?.category}
                                    helperText={errors?.category?.message}
                                >
                                    <MuiMenuItem value={"Submitted"}>Not Started</MuiMenuItem>
                                    <MuiMenuItem value={"Scheduled"}>Scheduled</MuiMenuItem>
                                    <MuiMenuItem value={"In Progress"}>In Progress</MuiMenuItem>
                                    <MuiMenuItem value={"On Hold"}>On Hold</MuiMenuItem>
                                    <MuiMenuItem value={"Completed"}>Completed</MuiMenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
        <Stack sx={{ mt: 3, mb: 12, backgroundColor: palette.white, px: 3, py: 20 / 8 }}>
            <Typography variant="body1" sx={{ fontWeight: 500, mb: 3, color: '#5A6A72' }}>Set Schedule</Typography>
            <Grid container rowSpacing={3}>
                <Grid item xs={12}>
                    <Controller
                        name="appointmentType"
                        control={control}
                        // rules={{
                        //     required: "Appointment Type is required.",
                        // }}
                        render={({ field }) => {
                            return (
                                <FormSelectField
                                    label="Select Appointment Type (Optional)"
                                    {...field}
                                    error={errors?.appointmentType}
                                    helperText={errors?.appointmentType?.message}
                                >
                                    <MuiMenuItem value={"Call"}>Appointment Required</MuiMenuItem>
                                    <MuiMenuItem value={"Permission Given"}>Permission to Enter</MuiMenuItem>
                                </FormSelectField>
                            );
                        }}
                    />
                </Grid>
                {apptType ==  'Call' && <><Grid item xs={12}>
                    <Controller
                        name="appointmentDate"
                        control={control}
                        rules={{
                            required: "Appointment Date is required.",
                        }}
                        render={({ field }) => {
                            return (
                                <BasicDatePicker value={DateTime.fromISO(field.value).toLocal()}
                                    onChange={(value:any) => {
                                        field.onChange(value?.toString());
                                    }} label="Date" />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Stack spacing={1}>
                        <label>
                            <Typography variant="body1" sx={{ color: '#303436bf' }}>
                                Select Appointment Time
                            </Typography>
                        </label>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Controller
                            name="appointmentStartTime"
                            control={control}
                            rules={{
                                required: "Appointment Start Time is required.",
                            }}
                            render={({ field }) => {
                                return (
                                    <BasicTimePicker value={DateTime.fromISO(field.value).toLocal()}
                                    onChange={(value:any) => {
                                        field.onChange(value?.toString());
                                    }}/>
                                );
                            }}
                        />
                        <Stack spacing={1} justifyContent="center" sx={{ pt: 1 }}>
                            <Box sx={{
                                height: '.25rem',
                                width: '1rem',
                                background: '#30343659',
                                borderRadius: '.25rem'
                            }}></Box>
                        </Stack>
                        <Controller
                            name="appointmentEndTime"
                            control={control}
                            // rules={{
                            //     required: "Appointment End Time is required.",
                            // }}
                            render={({ field }) => {
                                return (
                                    <BasicTimePicker value={DateTime.fromISO(field.value).toLocal()}
                                    onChange={(value:any) => {
                                        field.onChange(value?.toString());
                                    }} />
                                );
                            }}
                        />
                    </Stack>
                </Grid></>}
                <Grid item xs={12}>
                    <Controller
                        name="notes"
                        control={control}
                        // rules={{
                        //     required: "Notes is required.",
                        // }}
                        render={({ field }) => {
                            return (
                                <FormInputField
                                    multiline
                                    minRows={4}
                                    maxRows={4}
                                    label="Notes"
                                    placeholder={'Optional'}
                                    {...field}
                                    error={errors?.unitNumber}
                                    helperText={errors?.unitNumber?.message}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
        </Stack>
        <Stack sx={{ backgroundColor: palette.white, px: 3, py: 20 / 8, marginTop: 'auto', position: 'fixed', bottom: 0, width: '100%', maxWidth: '432px' }}>
            <Box sx={{ }}>
                <PrimaryButton isDisabled={!isValid} isFullWidth={true} onClick={onNext} btnText="Continue" />
            </Box>
        </Stack>
    </>
}