import { Button, Drawer, MenuItem as MuiMenuItem, Stack, Typography } from "@mui/material";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { ButtonCover } from "@ui/button";
import { useIsMobile } from "@hooks/useIsMobile";
import palette from "@utils/palette";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import TenantIssue from "./tenant-issue";
import { useEffect, useState } from "react";
import Confirm from "./confirm";
import TechniciansAndStatus from "./technicians-and-status";
import Success from "../success";
import axios from "axios";
import { useAuth } from "@contexts/AuthContext";
import { DateTime } from "luxon";
import moment from "moment-timezone";
import { getRequest, postRequest } from "@utils/http.service";
import { getGDAreas } from "@utils/helpers";

interface ICreateWO {
    open?: boolean;
    handleClose: (refresh?: boolean) => void;
}

enum CreateWOStep {
    'TenantIssue' = 'TenantIssue',
    'Confirm' = 'Confirm',
    'TechniciansAndStatus' = 'TechnicianAndStatus',
    'Success' = 'Success'
}

const MenuItem = styled(MuiMenuItem)(({ }) => ({
}))

export default function TenantIssueWO({ open, handleClose }: ICreateWO) {

    const [currentStep, setCurrentStep] = useState<CreateWOStep>(CreateWOStep.TenantIssue);

    const isMobile = useIsMobile();

    const {user} = useAuth();

    const [units, setUnits] = useState([] as any[]);

    const getUnits = async() => {
        const unitsData = await getRequest(`units/${user?.selectedProperty.id}`, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
        setUnits(unitsData.data);
    }

    useEffect(() => {
        getUnits();
    }, []);


    const {
        control,
        formState: { errors, isValid },
        getValues,
        setValue,
        watch
    } = useForm({
        defaultValues: {
            area: "",
            category: "",
            issueType: "",
            priority: "",
            unitNumber: "",
            description: "",
            technicians: "",
            workOrderStatus: "",
            appointmentType: "",
            appointmentDate: new Date().toISOString(),
            appointmentStartTime: new Date().toISOString(),
            appointmentEndTime: moment().add(30, 'minutes').toISOString(),
            notes: ""
        },
        mode: "onTouched",
    });

    const createWorkOrder = async () => {
        const vals = getValues();
        const date1 = moment(vals.appointmentDate).format().substring(0, 10);
        const time1 = moment(vals.appointmentStartTime).format().substring(11, 19);

        const reportedDate = moment().format().substring(0, 10);
        const reportedTime = moment().format().substring(11,19);
        
        const objToPost = {
            areas: getGDAreas([vals.area]),
            categoryId: vals.issueType, 
            category: user?.categories?.find(cat => cat.id === vals.category).subCategories.find((sC: any) => sC.id == vals.issueType).name,
            description: vals.description,
            unit: vals.unitNumber,
            assignedTo: vals.technicians, 
            assignedToPersonId: user?.techUsers.find(u => u.name == vals.technicians ? vals.technicians : '')?.thirdPartyId,
            status: vals.workOrderStatus,
            appointment: vals.appointmentType,
            scheduledDate: date1,
            scheduledTime: time1,
            notes: vals.notes,
            propertyId: user!!.selectedProperty.id,
            priority: vals.priority,
            reportedDate: reportedDate,
            reportedTime: reportedTime,
            reportedBy: units.find((u: any) => u.unitNumber === vals?.unitNumber)?.tenantName,
            phone: units.find((u: any) => u.unitNumber === vals?.unitNumber)?.tenantPhone
        }
        await postRequest(`work-orders`, objToPost, {
            headers: {
                Authorization: `Bearer ${user!!.token}`
            }
        });
    }

    const getCurrentStep = () => {
        switch (currentStep) {
            case CreateWOStep.TenantIssue:
                return <TenantIssue setValue={setValue} units={units} control={control} isValid={isValid} errors={errors} onNext={() => {
                    setCurrentStep(CreateWOStep.Confirm)
                }} />;
            case CreateWOStep.Confirm:
                return <Confirm units={units} onBack={()=>setCurrentStep(CreateWOStep.TenantIssue)} isValid={isValid} details={getValues()} onNext={() => setCurrentStep(CreateWOStep.TechniciansAndStatus)} />
            case CreateWOStep.TechniciansAndStatus:
                return <TechniciansAndStatus setValue={setValue} control={control} isValid={isValid} watch={watch} errors={errors} onNext={() => {
                    createWorkOrder();
                    setCurrentStep(CreateWOStep.Success)
                }} />;
        }
    }

    return <>
    { currentStep === CreateWOStep.Success ? <Success open={open} onClose={handleClose}/> : <Drawer
        anchor={isMobile ? 'bottom' : 'right'}
        open={open}
        sx={{
            '& .MuiPaper-root': {
                width: isMobile ? '100%' : 432,
                maxWidth: isMobile ? '100%' : 432,
                backgroundColor: '#F5F8FA'
            },
            '&.MuiModal-root.MuiDrawer-root': {
                zIndex: 1300
            }
        }}
    >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ py: 2.5, px: 3, boxShadow: '0px .125rem .5rem 0px rgba(48, 52, 54, 0.08)', backgroundColor: palette.white }}>
            <ButtonCover onClick={() => handleClose(false)}>
                <CloseRoundedIcon sx={{ color: '#303436bf', width: '2rem', height: '2rem' }} />
            </ButtonCover>
            <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.2, fontWeight: 400, color: '#5A6A72' }}>New Work Order</Typography>
            <Button></Button>
        </Stack>
        {getCurrentStep()}
    </Drawer> }</>
}