import SummaryCard from '@components/cards/SummaryCard';
import PageHeader from '@components/PageHeader';
import {
  Box,
  Grid,
  InputBase,
  Stack,
  styled,
  Typography,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import PropertySelector from '@components/inputs/PropertySelect';
import { useIsMobile } from '@hooks/useIsMobile';
import palette from '@utils/palette';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@contexts/AuthContext';
import WorkOrderDetail from '@components/workOrder/Detail';
import SelectDrawer from '@components/drawers/SelectDrawer';
import { getRequest } from '@utils/http.service';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Empty } from '@assets/images/empty.svg';
import DataRowCard from '@components/cards/DataRowCard';

const MobileSelect = styled(InputBase)(({ }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 8,
    background: '#9AD8B4'
  },
  '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
    borderRadius: '1rem'
  },
  '& .MuiSvgIcon-root': {
    height: '2rem',
    width: '2rem',
    marginLeft: '.375rem'
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    padding: '.375rem 2rem .375rem .75rem !important',
    fontSize: '.875rem',
    lineHeight: 1.43,
    fontWeight: 600
  },
  '&.MuiInputBase-root .MuiSvgIcon-root': {
    width: '1.25rem',
    height: '1.25rem'
  }
}));

export default function DashboardPage() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [jobs, setJobs] = useState<any[]>([]);
  const { user, updateTechList } = useAuth();
  const [assignedCount, setAssignedCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);

  useEffect(() => {
    getGridData();
  }, [user]);

  async function getGridData() {
    setIsLoading(true);
    let url = `work-orders?page=0&pageSize=10&propertyId=${user!!.selectedProperty.id}`;
    url += `&assignedToId=${user!!.id}&status=In Progress&status=Submitted&status=Scheduled&includeMR=true`;
    try {
      const resp = await getRequest(url, {
        headers: {
          Authorization: `Bearer ${user!!.token}`
        }
      });
      const responseData = resp.data.rows;
      setJobs(responseData);
      setIsLoading(false);

      const counts = await getRequest(`work-orders/wo-tech-counts?propertyId=${user!!.selectedProperty.id}&assignedToId=${user!!.id}&includeMR=true`, {
        headers: {
          Authorization: `Bearer ${user!!.token}`
        }
      });

      setAssignedCount(counts.data.assignedCount);
      setCompletedCount(counts.data.completedWos);

    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }

  const [selectedWo, setSelectedWo] = useState<any>(null);

  enum DrawerType {
    DateFilter = 'DateFilter',
    PropertyFilter = 'PropertyFilter'
  }

  const [drawer, setDrawer] = useState<{
    open: boolean,
    type: DrawerType | null
  }>({
    open: false,
    type: null
  });

  return (
    <>
    <Box>
      <PageHeader
        title={<FormattedMessage id="Dashboard"/>} />
      {isMobile ? (
          <Stack direction="row" spacing={2} sx={{ backgroundColor: palette.white, mx: -2, px: 2, mt: -4, pt: 4, mb: -2, pb: 2 }}>
            <Box onClick={() => {
              setDrawer({
                open: true,
                type: DrawerType.PropertyFilter
              })
            }}>
              { (user?.properties?.length || 0) > 1 ? <PropertySelector
                sx={{
                  pointerEvents: 'none'
                }} input={<MobileSelect />}
                renderValue={(displayValue) => (value: any) => displayValue}
              /> : '' }
            </Box>
          </Stack>
        ) : null}
      <Box sx={{ ...( isMobile ? { backgroundColor: palette.white, mx: -2, px: 2, mt: -2, pt: 2, pb: 2, mb: 3, boxShadow: '0px 2px 8px 0px rgba(48, 52, 54, 0.08)' } : { mb: 5})}}>
        <Typography variant={isMobile ? 'body1' : 'h5' } sx={{ fontWeight: 500, mb: 2 }}>{<FormattedMessage id="My Stats" />}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3} lg={3} onClick={() => {
            navigate('/work-orders');
          }}>
            <SummaryCard
              isMobile={isMobile}
              title={<><FormattedMessage id="Assigned Jobs" /> <br /><Box component='span' sx={{ color: '#5A6A72' }}>(<FormattedMessage id="Total" />)</Box></>}
              data={'' + assignedCount}
              iconBgColor="#CEE6FF"
              cardSx={{
                boxShadow: 'none'
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3} onClick={() => {
            navigate('/closed-work-orders');
          }}>
            <SummaryCard
              isMobile={isMobile}
              title={<><FormattedMessage id="Completed Jobs" /><br /><Box component='span' sx={{ color: '#5A6A72' }}>(<FormattedMessage id="Past 7 Days" />)</Box></>}
              data={'' + completedCount}
              iconBgColor="#D6F9E5"
              cardSx={{
                boxShadow: 'none'
              }}
            />
          </Grid>
        </Grid>

      </Box>

      <Stack sx={{ mb: 3 }} direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant={isMobile ? 'h5' : 'h4'}>
          <FormattedMessage id="Active Jobs"/>
        </Typography>
        <Button onClick={() => {
          navigate('/work-orders');
        }}><Typography variant="body1" sx={{ color: '#57BD83 '}}><FormattedMessage id="See All" /></Typography></Button>
      </Stack>
      {jobs.length ? <Grid container spacing={2}>
        {jobs.map((j, i)=>{
          return <Grid key={i} item xs={12} sm={6} md={4} lg={3}>
            <DataRowCard
              data={j}
              tech={true}
              onClick={(type) => {
                setSelectedWo(j);
              }}
            />
          </Grid>
        })}
      </Grid> : 
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 5, gap: 2}}>
        <Empty/>
        <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
          <Typography sx={{color: '#5A6A72', fontSize: '14px', lineHeight: '20px'}}>You have no active jobs.</Typography>
          <Typography sx={{color: '#5A6A72', fontSize: '14px', lineHeight: '20px'}}>Please check back later!</Typography>
        </Box>
      </Box>}
    </Box>
    {selectedWo && <WorkOrderDetail wo={selectedWo} onClose={() => {getGridData(); setSelectedWo(null)}}/>}
    {
        isMobile ?
          <>
            <SelectDrawer
              open={drawer.open && drawer.type === DrawerType.PropertyFilter}
              onClose={() => {
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              options={(user || [] as any).properties.map((p: any) => ({ label: p.name, value: p.id }))}
              onChange={(value) => {
                const sp = (user?.properties || []).find((p) => p.id == (value));
                updateTechList(sp);
                setDrawer({
                  open: false,
                  type: null
                })
              }}
              name="property-filter"
              selected={user!!.selectedProperty.id}
              submitBtnLabel='View Property'
              heading="Select Property to View"
            />
          </> : ''
      }
    </>
  );
}
