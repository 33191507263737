import { Box, Grid, Typography } from '@mui/material';
import { PRIORITY_TYPE } from '@utils/models';
import { getColorByText, getInitials, getPriorityColor, getTitle, statusOptions } from '@utils/helpers';
import FlagIcon from '@mui/icons-material/Flag';
import { ReactComponent as PersonAddIcon } from '../../assets/icons/person-add.svg';
import ModeEditIcon from '@mui/icons-material/ModeEditRounded';
import VendorIcon from '@mui/icons-material/AddBusinessRounded';
import { FormattedMessage } from 'react-intl';

interface IProps {
  data: any;
  onClick: (touchPoint: string) => void;
  tech?: boolean;
}

export default function DataRowCard({ data, onClick, tech = false }: IProps) {
  function getPriority(priority: PRIORITY_TYPE) {
    return (
      <Box color={getPriorityColor(priority)}>
        <FlagIcon />
      </Box>
    );
  }

  return (
    <Grid
      container
      sx={{
        pt: 2,
        pb: 1,
        background: '#FFFFFF',
        borderRadius: '16px',
        boxShadow: '0px 6px 24px 0px #3034361A'
      }}
      onClick={(e) => {
        onClick('details');
      }}
    >
      <Grid item xs={12}>
        <Box sx={{display: 'flex', px: 2, marginBottom: 1}}>
          <Typography sx={{
            padding: 0.5,
            borderRadius: 2,
            fontSize: '12px',
            fontWeight: 500,
            background: statusOptions.find((s) => s.name === data.status || (s.name === 'Unassigned' && data.status === 'Submitted'))?.background,
            color: statusOptions.find((s) => s.name === data.status || (s.name === 'Unassigned' && data.status === 'Submitted'))?.color,
          }}>{data.status}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <Typography variant="h5" fontWeight={500} sx={{fontSize: '16px'}}>
              {data.isMakeReady ? getTitle(data.description) : data.category}
            </Typography>
            {!data.isMakeReady && <Box
              onClick={(e) => {
                e.stopPropagation();
                onClick('priority');
              }}
            >
              {getPriority(data.priority)}
            </Box>}
          </Box>
          {!data.isMakeReady && <Typography variant="body1" fontWeight={500} color={'#8D9DA5'}>
            <FormattedMessage id="Unit" /> {data.unit}
          </Typography>}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'start',
            gap: 1,
            px: 2,
          }}
        >
          <Typography color={'#8D9DA5'}> #{data.number} </Typography>
          <Typography
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            | {data.description}
          </Typography>
        </Box>
        {tech && !data.isMakeReady && <Box sx={{px: 2}}>
          <Typography variant="body2" color={'#8D9DA5'} lineHeight={'30px'}>
            {data.appointment}
          </Typography>
        </Box>}
        {<Box sx={{ border: '1px solid #EDF1F3', my: 1 }}></Box>}
        {<Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: data.isMakeReady ? 'flex-start' : 'space-between',
            gap: data.isMakeReady ? 2: 0,
            px: 2
          }}
        >
          <Box
            sx={{
              width: '32px',
              height: '32px',
              lineHeight: '32px',
              border: data.assignedTo && '1px solid #00000014',
              borderRadius: '50%',
              textAlign: 'center',
              background: data.assignedTo && getColorByText(data.assignedTo),
              color: '#fff',
              fontSize: '12px',
              zIndex: 100
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClick('technician');
            }}
          >
            {data.assignedTo && getInitials(data.assignedTo)}
            {data.assignedTo && (
              <Box
                sx={{
                  width: '15px',
                  height: '15px',
                  position: 'relative',
                  bottom: '15px',
                  left: '24px',
                  borderRadius: '50%',
                  background: 'white',
                  zIndex: 5,
                  display:'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center'
                }}
              >
              </Box>
            )}
            {data.assignedTo && (
              <Box
                sx={{
                  width: '15px',
                  height: '15px',
                  position: 'relative',
                  bottom: '29px',
                  left: '25px',
                  borderRadius: '50%',
                  background: 'var(--dark-disabled, rgba(48, 52, 54, 0.14))',
                  // transform: 'scale(.9);',
                  zIndex: 5,
                  display:'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center'
                }}
              >
                <ModeEditIcon sx={{  width: '10px', height: '10px' }} />
              </Box>
            )}
            {!data.assignedTo && <PersonAddIcon width={32} height={32} />}
          </Box>
          {data.isMakeReady && <Box
            sx={{
              width: '32px',
              height: '32px',
              lineHeight: '32px',
              border: data.vendor && '1px solid #00000014',
              borderRadius: '50%',
              textAlign: 'center',
              background: data.vendor && getColorByText(data.vendor),
              color: '#fff',
              fontSize: '12px',
              zIndex: 100
            }}
            onClick={(e) => {
              if(!tech) {
                e.stopPropagation();
                onClick('vendor');
              }
            }}
          >
            {data.vendor && getInitials(data.vendor)}
            {data.vendor && !tech && (
              <Box
                sx={{
                  width: '15px',
                  height: '15px',
                  position: 'relative',
                  bottom: '15px',
                  left: '24px',
                  borderRadius: '50%',
                  background: 'white',
                  zIndex: 5,
                  display:'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center'
                }}
              >
              </Box>
            )}
            {data.vendor && !tech && (
              <Box
                sx={{
                  width: '15px',
                  height: '15px',
                  position: 'relative',
                  bottom: '29px',
                  left: '25px',
                  borderRadius: '50%',
                  background: 'var(--dark-disabled, rgba(48, 52, 54, 0.14))',
                  // transform: 'scale(.9);',
                  zIndex: 5,
                  display:'flex', 
                  justifyContent: 'center', 
                  alignItems: 'center'
                }}
              >
                <ModeEditIcon sx={{  width: '10px', height: '10px' }} />
              </Box>
            )}
            {!data.vendor && !tech && <VendorIcon sx={{fontSize: '32px', color: '#30343659', borderRadius: '32px', padding: '4px',  border: '1px dashed #30343659'}}/>}
          </Box>}
          {!data.isMakeReady && <Box>
            <Typography variant="body2" color={'#8D9DA5'} lineHeight={'30px'}>
              {data.appointment}
            </Typography>
          </Box>}
        </Box>}
      </Grid>
    </Grid>
  );
}
